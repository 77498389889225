import createApp from './main';

// Mount the mixins for automatically fetching route data.
// https://ssr.vuejs.org/guide/data.html#client-data-fetching
import mountAsyncDataMixin from './utils/asyncDataMixin';

// TODO: remove! used for debugging.
// import './utils/highlightOnUpdate';

// Create an instance of the app.
const { app, router, store } = createApp();

// Add the AsyncData mixin to the vue instance.
mountAsyncDataMixin({ router, store });

// If the page was SSR'ed then use the global
// state left by the server as the state starting point.
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__);
}

// Fetch the general website data.
store.dispatch('fetchGeneral');

// Mount it to the DOM only after the router resolved.
// https://ssr.vuejs.org/guide/routing.html#code-splitting
router.onReady(() => {
	app.$mount('#root');
});

/*
 * Before changing the route we need to reset some things like for
 * example close the menu if its open, or clear state for the previous route.
 */
router.beforeEach((to, from, next) => {
	// This function also runs on first render,
	// so we return if the "from" is emmpty.
	if (from.matched.length === 0) return;

	// Clear route specific data.
	store.commit('clearRouteData');

	// Close the menu if its open.
	store.commit('closeMenu');

	// Scroll to the top
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});

	// Move to the next router hook(middleware actually)
	next();
});

/*
 * Update google analytics after route change.
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
router.afterEach((to, from) => {
	// Dont update if we are visiting the same page.
	// This can also happen if in the first page load.
	if (to.path === from.path) return;

	// Will be undefiend in Development.
	if (window.gtag !== undefined) {
		window.gtag('config', 'UA-65676426-1', {
			// Friendly name for the route.
			page_title: to.name,
			// Url.
			page_path: to.path
		});
	}
});
