import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-dom';

// Instantiate the API for the specific endpoint of this website.
const prismicApi = Prismic.getApi('https://doctorice.prismic.io/api/v2');

export default class Api {
	static async getGeneralInfo() {
		const api = await prismicApi;
		const response = await api.query(
			Prismic.Predicates.at('document.type', 'general')
		);

		return response.results[0].data;
	}

	static async getSingle(singleName) {
		const api = await prismicApi;
		const document = await api.getSingle(singleName);
		return {
			id: document.id,
			first_publication_date: document.first_publication_date,
			last_publication_date: document.last_publication_date,
			...document.data
		};
	}

	static async getSectionInfo(sectionName) {
		const api = await prismicApi;
		const response = await api.query(
			Prismic.Predicates.at('my.section.slug', sectionName)
		);

		return response.results[0].data;
	}

	static async getCategories() {
		const api = await prismicApi;
		const response = await api.query(
			Prismic.Predicates.at('document.type', 'category'),
			// Order the results by publication date.
			{ orderings: '[document.first_publication_date]' }
		);

		const categories = response.results.map(doc => {
			return {
				id: doc.id,
				uid: doc.uid,
				name: doc.data.name
			};
		});

		// Save the categories in the cache.
		return categories;
	}

	static async getCategoryIdByName(categoryName) {
		const categories = await Api.getCategories();
		const category = categories.find(cat => cat.uid === categoryName);
		return category.id;
	}

	static async getProductTypes() {
		const api = await prismicApi;
		const response = await api.query(
			Prismic.Predicates.at('document.type', 'product_type'),
			// Order the results by publication date.
			{ orderings: '[document.first_publication_date]' }
		);

		return response.results.map(doc => {
			return {
				id: doc.id,
				uid: doc.uid,
				name: doc.data.name
			};
		});
	}

	static async getProducts({ page, amount, category, typeId } = {}) {
		const api = await prismicApi;
		const queryArray = [Prismic.Predicates.at('document.type', 'product')];
		const queryFilters = { orderings: '[document.last_publication_date]' };

		// If a category was requested, then add that to the query array.
		if (category !== undefined) {
			const categoryId = await Api.getCategoryIdByName(category);
			queryArray.push(Prismic.Predicates.at('my.product.category', categoryId));
		}

		// If a product type was requested, then add that to the query array.
		if (typeId !== undefined) {
			queryArray.push(Prismic.Predicates.at('my.product.type', typeId));
		}

		// If a an amount was requested, add it to the filters.
		if (amount !== undefined && amount !== 0) {
			queryFilters.pageSize = amount;
		}

		// If the user requested a specific page, add it to the query.
		if (page !== undefined) {
			queryFilters.page = page;
		}

		// Send the request.
		const response = await api.query(queryArray, queryFilters);

		// map the results for easier use.
		const results = response.results.map(doc => {
			doc.data.id = doc.id;
			return doc.data;
		});

		// Return an object with the mapped results and pagination.
		return {
			page: response.page,
			total_pages: response.total_pages,
			results_size: response.results_size,
			total_results_size: response.total_results_size,
			results
		};
	}

	static async getArticles({ page, amount } = {}) {
		const api = await prismicApi;
		const queryArray = [Prismic.Predicates.at('document.type', 'article')];
		const queryFilters = { orderings: '[document.last_publication_date]' };

		// If a an amount was requested, add it to the filters.
		if (amount !== undefined && amount !== 0) {
			queryFilters.pageSize = amount;
		}

		// If a apecific page was requested, add it to the query.
		if (page !== undefined) {
			queryFilters.page = page;
		}

		// Send the request.
		const response = await api.query(queryArray, queryFilters);

		// Filter the results for easier use.
		return response.results.map(doc => {
			doc.data.id = doc.id;
			doc.data.uid = doc.uid;
			return doc.data;
		});
	}

	static async getEntryById(id) {
		const api = await prismicApi;
		const product = await api.getByID(id);
		return product && product.data;
	}

	static async getArticleByUID(uid) {
		const api = await prismicApi;
		const article = await api.getByUID('article', uid);
		return article && article.data;
	}

	static renderRichText(richTextObject) {
		return RichText.asHtml(richTextObject, Api.linkResolver);
	}

	static renderPlainText(richTextObject) {
		return RichText.asText(richTextObject, Api.linkResolver);
	}

	static linkResolver(doc) {
		if (doc.type === 'section') {
			return `/${doc.slug}/`;
		}

		return '/404';
	}
}
