<template>
	<header id="header" :class="{'main-menu-open': isMenuOpen}">
		<div class="width-container">
			<router-link id="logo" to="/">
				<img src="/images/logo.svg" alt="Home">
			</router-link>
			<main-menu />
			<contact-phone />
			<div id="hamburger" @click="toggleMenu">
				<div class="inner-wrap">
					<div class="line" />
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import Menu from './MainMenu.vue';
import ContactPhone from '../general/ContactPhone.vue';

export default {
	components: {
		'main-menu': Menu,
		'contact-phone': ContactPhone
	},

	computed: {
		isMenuOpen() {
			return this.$store.state.ui.isMenuOpen;
		}
	},

	methods: {
		toggleMenu() {
			this.$store.commit('toggleMenu');
			// document.body.classList.toggle('main-menu-open');
		}
	}
};
</script>
