<template>
	<div id="root">
		<app-header />
		<router-view />
		<app-footer />
	</div>
</template>

<script>
import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';

export default {
	name: 'App',
	// Page metadata.
	metaInfo() {
		const general = this.$store.state.general;

		return {
			titleTemplate: general.name + ' | %s',
			meta: [
				{
					vmid: 'description',
					name: 'description',
					content: general.seo_slogan
				},
				{
					vmid: 'og:description',
					property: 'og:description',
					content: general.seo_slogan
				},
				{
					vmid: 'og:image',
					property: 'og:image',
					content: '/images/facebook-share-image.jpg'
				}
			]
		};
	},
	components: {
		'app-header': Header,
		'app-footer': Footer
	}
};
</script>
