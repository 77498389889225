var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("nav", { attrs: { id: "menu" } }, [
    _vm.parent.$store.state.general.menu === undefined
      ? _c("div", { staticClass: "placeholder" })
      : _c(
          "ul",
          _vm._l(_vm.parent.$store.state.general.menu, function(item) {
            return _c(
              "li",
              { key: item.link },
              [
                _c("router-link", { attrs: { to: item.link } }, [
                  _vm._v(_vm._s(item.title))
                ])
              ],
              1
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }