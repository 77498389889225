/*
 * Recursively run `asyncData` method of components
 * and their child components and return an array of promises for the requests.
 */
export default function recursiveAsyncData(
	componentsArray,
	{ store, route, router }
) {
	const asyncDataPromises = [];

	for (let Component of componentsArray) {
		// If the component has an `asyncData` method then run it and add it
		// to the promises array.
		if (Component.asyncData !== undefined) {
			asyncDataPromises.push(Component.asyncData({ store, route, router }));
		}

		// If the component has child components
		// then recursively run this function on them.
		if (Component.components) {
			// Use `Object.values` to convert the object to an array.
			const childComponents = Object.values(Component.components);

			// Recursively run this function on the child
			// components and add the `asyncData` promises to the array.
			asyncDataPromises.push(
				...recursiveAsyncData(childComponents, { store, route, router })
			);
		}
	}

	return asyncDataPromises;
}
