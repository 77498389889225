var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("footer", { attrs: { id: "footer" } }, [
    _vm._v(
      "\n\t" +
        _vm._s(_vm.parent.$store.state.general.name) +
        " -\n\t" +
        _vm._s(_vm.parent.$store.state.general.slogan) +
        " ©\n\t" +
        _vm._s(new Date().getFullYear()) +
        "\n"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }