export default {
	toggleMenu(state) {
		state.ui = {
			...state.ui,
			isMenuOpen: state.ui.isMenuOpen ? false : true
		};
	},

	closeMenu(state) {
		state.ui = {
			...state.ui,
			isMenuOpen: false
		};
	},

	updateGeneral(state, data) {
		state.general = data;
	},

	updateCategories(state, data) {
		state.categories = data;
	},

	updateProductTypes(state, data) {
		state.productTypes = data;
	},

	updateSections(state, data) {
		state.sections = { ...state.sections, ...data };
	},

	updateRouteData(state, data) {
		state.routeData = { ...state.routeData, ...data };
	},

	clearRouteData(state) {
		state.routeData = {};
	}
};
