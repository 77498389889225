var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "contact-phone" },
    [
      _vm.parent.$store.state.general.phone === undefined
        ? [_c("div", { staticClass: "placeholder" })]
        : [
            _c("div", { staticClass: "phrase" }, [_vm._t("default")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "phone-number" }, [
              _c(
                "svg",
                { attrs: { width: "17", height: "17", viewBox: "0 0 17 17" } },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M3.3261 17C10.866 17 17 10.8659 17 3.32606C17 3.22813 16.9612 3.13389 16.8917 3.06478L14.6743 0.847389C14.5298 0.702889 14.2963 0.702889 14.1518 0.847389L10.4561 4.54304C10.3116 4.68754 10.3116 4.92111 10.4561 5.06561L12.4122 7.02171L7.02175 12.4122L5.06564 10.4561C4.92114 10.3116 4.68757 10.3116 4.54307 10.4561L0.847422 14.1517C0.702921 14.2962 0.702921 14.5298 0.847422 14.6743L3.06481 16.8917C3.13392 16.9612 3.22816 17 3.3261 17Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M0.369565 7.3913C0.573565 7.3913 0.73913 7.22574 0.73913 7.02174C0.73913 3.55744 3.55744 0.73913 7.02174 0.73913C7.22574 0.73913 7.3913 0.573565 7.3913 0.369565C7.3913 0.165565 7.22574 0 7.02174 0C3.1498 0 0 3.1498 0 7.02174C0 7.22574 0.165565 7.3913 0.369565 7.3913Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M3.32609 7.39129C3.53009 7.39129 3.69565 7.22573 3.69565 7.02173C3.69565 5.18795 5.18796 3.69564 7.02174 3.69564C7.22574 3.69564 7.3913 3.53008 7.3913 3.32608C7.3913 3.12208 7.22574 2.95651 7.02174 2.95651C4.78033 2.95651 2.95652 4.78032 2.95652 7.02173C2.95652 7.22573 3.12209 7.39129 3.32609 7.39129Z",
                      fill: "white"
                    }
                  })
                ]
              ),
              _c("span", [
                _vm._v(_vm._s(_vm.parent.$store.state.general.phone))
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }