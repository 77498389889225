import API from '../api';

export default {
	async fetchGeneral(ctx) {
		const data = await API.getGeneralInfo();
		ctx.commit('updateGeneral', data);
	},

	async fetchCategories({ commit, state }) {
		// If we already have the categories, bail out!
		if (state.categories.length > 0) return;

		const data = await API.getCategories();
		commit('updateCategories', data);
	},

	async fetchProductTypes({ commit, state }) {
		// If we already have the types, bail out!
		if (state.productTypes.length > 0) return;

		const data = await API.getProductTypes();
		commit('updateProductTypes', data);
	},

	async fetchSection({ commit, state }, sectionName) {
		// If we already have the requested section, bail out!
		if (state.sections[sectionName] !== undefined) return;

		const data = await API.getSectionInfo(sectionName);
		commit('updateSections', { [sectionName]: data });
	},

	async fetchProducts({ commit }, { amount, category }) {
		const data = await API.getProducts({ amount, category });
		commit('updateProducts', data);
	}
};
