import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Reason for exporting a function that creates a new instance:
// https://ssr.vuejs.org/guide/structure.html#avoid-stateful-singletons
export default function() {
	return new Router({
		mode: 'history',
		// Routes are imported async. with dynamic imports.
		// https://ssr.vuejs.org/guide/routing.html#code-splitting
		routes: [
			{
				name: 'Home',
				path: '/',
				component: () => import('./components/home/Home.vue')
			},
			{
				name: 'Product',
				path: '/shop/:category(new|used)/:type/:productId',
				component: () => import('./components/shop/Product.vue')
			},
			{
				name: 'Catalogue',
				path: '/shop/:category(new|used)?/:type?',
				component: () => import('./components/shop/Shop.vue')
			},
			{
				name: 'Articles',
				path: '/articles',
				component: () => import('./components/articles/Articles.vue')
			},
			{
				name: 'Single Article',
				path: '/articles/:id',
				component: () => import('./components/articles/Article.vue')
			},
			{
				name: 'Services',
				path: '/services',
				component: () => import('./components/articles/Article.vue')
			},
			{
				name: 'Contact',
				path: '/contact',
				component: () => import('./components/articles/Article.vue')
			},
			{
				name: 'Not Found',
				path: '*',
				component: () => import('./components/404.vue')
			}
		]
	});
}
