import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

// Reason for exporting a function that creates a new instance:
// https://ssr.vuejs.org/guide/structure.html#avoid-stateful-singletons
export default function() {
	return new Vuex.Store({
		state,
		actions,
		mutations
	});
}
