export default {
	/*
	 * Holds the general ui state
	 * for components that are used everywhere.
	 */
	ui: {},
	/*
	 * General website data like:
	 * Website title.
 	 * Websites Description(general).
 	 * Main menu items.
   * etc.
	 */
	general: {},

	/*
	 * Current Route data like:
	 * Page title,
	 * Page metadata,
	 * Route's component data.
	 * etc.
	 */
	routeData: {},

	/*
	 * Sections metadata.
	 */
	sections: {},

	/*
	 * Shop categories & product Types.
	 */
	categories: [],
	productTypes: []
};
