var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: { "main-menu-open": _vm.isMenuOpen }, attrs: { id: "header" } },
    [
      _c(
        "div",
        { staticClass: "width-container" },
        [
          _c("router-link", { attrs: { id: "logo", to: "/" } }, [
            _c("img", { attrs: { src: "/images/logo.svg", alt: "Home" } })
          ]),
          _vm._v(" "),
          _c("main-menu"),
          _vm._v(" "),
          _c("contact-phone"),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "hamburger" }, on: { click: _vm.toggleMenu } },
            [_vm._m(0)]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-wrap" }, [
      _c("div", { staticClass: "line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }