/*
 * This file containts a mixins that automatically
 * fetches the data needed for components by runing
 * the `asyncData` method inside vue instances that have it.
 *
 * For more info:
 * https://ssr.vuejs.org/guide/data.html#client-data-fetching
 */
import Vue from 'vue';
import recursiveAsyncData from './recursiveAsyncData';

export default function mountMixin({ store, router }) {
	Vue.mixin({
		/*
		 * Re-fetch also when the component
		 * is reused and the full route changes,
		 * `beforeRouteUpdate` isn't called in that case.
		 */
		beforeRouteEnter(to, from, next) {
			const matchedComponents = router.getMatchedComponents(to);
			recursiveAsyncData(matchedComponents, { store, route: to, router });
			next();
		},

		/*
		 * Run the `asyncData` method also
		 * when a route component is reused
		 * (same route, but different params).
		 */
		beforeRouteUpdate(to, from, next) {
			const matchedComponents = router.getMatchedComponents(to);
			recursiveAsyncData(matchedComponents, { store, route: to, router });
			next();
		}
	});
}
