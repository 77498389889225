// This is the universal entry point to the app.
// It means that both the server and the client will run this file.
import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import createStore from './store/store';
import { sync } from 'vuex-router-sync';
import Meta from 'vue-meta';

// Used to set metadata on the head.
Vue.use(Meta);

// Reason for exporting a function that creates a new instance:
// https://ssr.vuejs.org/guide/structure.html#avoid-stateful-singletons
export default function() {
	const router = createRouter();
	const store = createStore();

	// sync so that route state is available as part of the store
	sync(store, router);

	const app = new Vue({
		router,
		store,
		render(h) {
			return h(App);
		}
	});

	return { app, router, store };
}
